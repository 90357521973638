import React from 'react';

// libraries
import { css } from '@emotion/core';

import Head from 'components/Head';
import Link from 'components/Link';
import Markdown from 'components/Markdown';
import MainLayout from 'components/MainLayout';
import Table from 'components/Table';

import DocsLayout from './Layout';

interface Props {
  pageContext: { docs: Docs };
}

export default function SDKDocs(props: Props) {
  const { docs } = props.pageContext;

  return (
    <MainLayout>
      <Head title="SDK Documentation" />
      <DocsLayout index title="Parsec SDK Overview">
        <p>
          The Parsec SDK allows your application to make interactive,
          low–latency peer–to–peer connections for the purpose of game
          streaming. The SDK handles the low level internals of peer–to–peer
          connectivity, networking, and hardware accelerated video/audio
          processing. It is lightweight, consisting of a{' '}
          <Link href="https://github.com/parsec-cloud/parsec-sdk/blob/master/sdk/parsec.h">
            single header file
          </Link>{' '}
          and a shared object &lt; 5MB.
        </p>

        <p>
          Visit our{' '}
          <Link href="https://github.com/parsec-cloud/parsec-sdk">
            GitHub repo
          </Link>{' '}
          for binaries and examples. Visit our{' '}
          <Link href="https://discord.gg/xQaTQt2">Discord server</Link> for
          discussion and support.
        </p>

        <h3 id="instance" css={h3}>
          Instance Functions
        </h3>
        <p>
          Instance functions operate either globally or on the top–level{' '}
          <code>Parsec</code> instance.
        </p>
        <ul>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecInit">ParsecInit</Link>
            </code>{' '}
            to create a new <code>Parsec</code> instance used for either making
            a client connection or hosting.
          </li>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecSetLogCallback">
                ParsecSetLogCallback
              </Link>
            </code>{' '}
            to receive log messages globally.
          </li>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecDestroy">ParsecDestroy</Link>
            </code>{' '}
            when finished.
          </li>
        </ul>
        <p></p>

        <Table
          css={{ width: '100%' }}
          columns={['Name', 'Description']}
          data={docs.func
            .filter(func => func.brief && func.group === 'instance')
            .map(func => [
              <code>
                <Markdown>{func.name}</Markdown>
              </code>,
              <Markdown>{func.brief || ''}</Markdown>
            ])}
        />

        <h3 id="client" css={h3}>
          Client Functions
        </h3>
        <p>
          Client functions deal with making a connection to a host, sending
          input, rendering frames, and playing audio.{' '}
        </p>
        <ul>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecClientConnect">
                ParsecClientConnect
              </Link>
            </code>{' '}
            to make a connection to a host. This function requires a{' '}
            <code>sessionID</code> and <code>peerID</code> obtained via the{' '}
            <Link href="https://github.com/parsec-cloud/parsec-sdk/tree/master/api">
              Parsec API.
            </Link>
          </li>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecClientGetStatus">
                ParsecClientGetStatus
              </Link>
            </code>{' '}
            periodically to check if the connection remains active.
          </li>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecClientSetDimensions">
                ParsecClientSetDimensions
              </Link>
            </code>{' '}
            at least once and whenever the client window resizes.
          </li>
          <li>
            Call either{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecClientPollFrame">
                ParsecClientPollFrame
              </Link>
            </code>{' '}
            or{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecClientGLRenderFrame">
                ParsecClientGLRenderFrame
              </Link>
            </code>{' '}
            to process and display incoming video frames. If using{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecClientGLRenderFrame">
                ParscClientGLRenderFrame
              </Link>
            </code>
            , call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecClientGLDestroy">
                ParsecClientGLDestroy
              </Link>
            </code>{' '}
            when finished on the thread that was doing the rendering.
          </li>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecClientPollAudio">
                ParsecClientPollAudio
              </Link>
            </code>{' '}
            to process then play audio from the host.
          </li>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecClientPollEvents">
                ParsecClientPollEvents
              </Link>
            </code>{' '}
            to respond to host events such as cursor changes and user defined
            messages.
          </li>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecClientSendMessage">
                ParsecClientSendMessage
              </Link>
            </code>{' '}
            to send input to the host.
          </li>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecClientDisconnect">
                ParsecClientDisconnect
              </Link>
            </code>{' '}
            when finished.
          </li>
        </ul>
        <p></p>

        <Table
          css={{ width: '100%' }}
          columns={['Name', 'Description']}
          data={docs.func
            .filter(func => func.brief && func.group === 'client')
            .map(func => [
              <code>
                <Markdown>{func.name}</Markdown>
              </code>,
              <Markdown>{func.brief || ''}</Markdown>
            ])}
        />

        <h3 id="host" css={h3}>
          Host Functions
        </h3>
        <p>
          Host functions deal with accepting guest connections, polling
          host/guest events, and in <code>HOST_GAME</code> submitting
          video/audio.
        </p>
        <ul>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecHostStart">ParsecHostStart</Link>
            </code>{' '}
            to begin a hosting session. This function requires a{' '}
            <code>sessionID</code> obtained via the{' '}
            <Link href="https://github.com/parsec-cloud/parsec-sdk/tree/master/api">
              Parsec API.
            </Link>
          </li>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecHostGetGuests">
                ParsecHostGetGuests
              </Link>
            </code>{' '}
            to retrieve a list of currently connected guests.
          </li>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecHostPollEvents">
                ParsecHostPollEvents
              </Link>
            </code>{' '}
            to respond to guest events such as connect, disconnect, user and
            user defined messages.
          </li>
          <li>
            In <code>HOST_GAME</code>, call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecHostPollEvents">
                ParsecHostPollInput
              </Link>
            </code>{' '}
            to receieve input messages from all guests.
          </li>
          <li>
            In <code>HOST_GAME</code>, call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecHostSubmitAudio">
                ParsecHostSubmitAudio
              </Link>
            </code>{' '}
            to send game audio to all guests.
          </li>
          <li>
            In <code>HOST_GAME</code>, call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecHostD3D11SubmitFrame">
                ParsecHostD3D11SubmitFrame
              </Link>
            </code>{' '}
            (or similar) to send rendered frames to all guests.
          </li>
          <li>
            Call{' '}
            <code>
              <Link href="/docs/sdk/func/ParsecHostStop">ParsecHostStop</Link>
            </code>{' '}
            when finished.
          </li>
        </ul>
        <p></p>

        <Table
          css={{ width: '100%' }}
          columns={['Name', 'Description']}
          data={docs.func
            .filter(func => func.brief && func.group === 'host')
            .map(func => [
              <code>
                <Markdown>{func.name}</Markdown>
              </code>,
              <Markdown>{func.brief || ''}</Markdown>
            ])}
        />

        <h2 id="struct" css={h2}>
          Structures
        </h2>
        <Table
          css={{ width: '100%' }}
          columns={['Name', 'Description']}
          data={docs.struct
            .filter(struct => struct.brief)
            .map(struct => [
              <code>
                <Markdown>{struct.name}</Markdown>
              </code>,
              <Markdown>{struct.brief || ''}</Markdown>
            ])}
        />

        <h2 id="enum" css={h2}>
          Enumerations
        </h2>
        <Table
          css={{ width: '100%' }}
          columns={['Name', 'Description']}
          data={docs.enum
            .filter(enumeration => enumeration.brief)
            .map(enumeration => [
              <code>
                <Markdown>{enumeration.name}</Markdown>
              </code>,
              <Markdown>{enumeration.brief || ''}</Markdown>
            ])}
        />

        <h2 id="typedef" css={h2}>
          Typedefs
        </h2>
        <Table
          css={{ width: '100%' }}
          columns={['Type', 'Name (Params)']}
          data={docs.typedef
            .filter(typedef => typedef.brief)
            .map(typedef => [
              <code>
                <Markdown>{typedef.name}</Markdown>
              </code>,
              <Markdown>{typedef.brief || ''}</Markdown>
            ])}
        />

        <h2 id="define" css={h2}>
          Defines
        </h2>
        <Table
          css={{ width: '100%' }}
          columns={['Name', 'Description']}
          data={docs.define
            .filter(define => define.brief)
            .map(define => [
              <code>
                <Markdown>{define.name}</Markdown>
              </code>,
              <Markdown>{define.brief || ''}</Markdown>
            ])}
        />
      </DocsLayout>
    </MainLayout>
  );
}

const h2 = css`
  padding: 4rem 0 2rem;
`;

const h3 = css`
  padding: 4rem 0 2rem;
`;
